<template>
	<div class="page">
		<BreadCrumb />

		<div class="page-body">
			<QueryModule :query-module="queryModule" @queryRes="queryRes" @reset="reset">
				<el-col :span="8">
					<el-form-item label="知识库标题"><el-input v-model="queryModule.projectName" /></el-form-item>
				</el-col>
			</QueryModule>

			<el-button type="primary" @click="addDialog()" size="mini">新增知识库</el-button>

			<el-row :gutter="20">
				<el-col :span="6">
					<div class="tree_title">
						<p class="first_class_tittle">知识分类</p>
						<div>
							<el-button circle size="mini" @click="appendKnowledgeBase(0)">
								<el-icon :size="15"><i-plus /></el-icon>
							</el-button>
						</div>
					</div>
					<div class="tree_box border_1-dcdfe6">
						<el-tree
							:data="knowledgeBaseTree_data"
							node-key="id"
							:default-expand-all="false"
							:expand-on-click-node="false"
							:render-content="renderContent"
							@node-click="treeClick"
						/>
					</div>
				</el-col>

				<el-col :span="18">
					<p class="first_class_tittle">知识库列表</p>
					<el-table v-loading="loading" :data="tableData" fit stripe>
						<el-table-column :index="(queryModule.page - 1) * pageSize + 1" label="序号" type="index" />
						<el-table-column label="知识库标题">
							<template #default="scope">
								<el-button size="mini" type="text" @click="toRrojectDetails(scope.row)">{{ scope.row.knowledgeBaseTitle }}</el-button>
							</template>
						</el-table-column>
						<el-table-column label="知识库简介" prop="knowledgeBaseSummary" />

						<el-table-column label="知识库类型" prop="knowledgeBaseType" />

						<el-table-column label="分类标题" prop="categoryTitle" />
						<el-table-column label="操作">
							<template #default="scope">
								<el-tooltip class="item" effect="light" content="修改知识库" placement="top-end">
									<el-button type="primary" circle size="mini" @click="updataSourceData(scope.row)">
										<el-icon :size="15"><i-edit /></el-icon>
									</el-button>
								</el-tooltip>
								<el-tooltip class="item" content="删除知识库" effect="light" placement="top-end">
									<el-button type="danger" circle size="mini" @click="delentSourceData(scope.row)">
										<el-icon :size="15"><i-delete /></el-icon>
									</el-button>
								</el-tooltip>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination
						v-model:currentPage="queryModule.page"
						:page-size="pageSize"
						:total="total"
						background
						layout="total, prev, pager, next, jumper"
						@current-change="handleCurrentChange"
					/>
				</el-col>
			</el-row>
		</div>
	</div>
	<!-- 修改知识库弹窗 -->
	<el-dialog v-model="dataSourceAdd" :title="dataSourceTitle">
		<el-form ref="form" :model="form" :rules="updateKnowledgeRules" label-width="100px">
			<el-form-item label="知识库标题:" prop="knowledgeBaseTitle"><el-input v-model="form.knowledgeBaseTitle" placeholder="请输入知识库标题" /></el-form-item>
			<el-form-item label="知识库简介:" prop="knowledgeBaseSummary"><el-input v-model="form.knowledgeBaseSummary" placeholder="请输入知识库简介" /></el-form-item>
			<div class="button_group">
				<el-button @click="dataSourceAdd = false">取消</el-button>
				<el-button v-if="dataSourceUpdate" type="primary" @click="UpdateOnSubmit('form')">修 改</el-button>
			</div>
		</el-form>
	</el-dialog>
	<!-- 文档知识弹框 -->
	<el-dialog v-model="DocumentKnowledge" :title="dataSourceTitle" fullscreen>
		<QueryModule :query-module="queryModule" @queryRes="knowledgeRes" @reset="knowledgereset">
			<el-col :span="8">
				<el-form-item label="文档知识标题"><el-input v-model="queryModule.documentKnowledgeName" /></el-form-item>
			</el-col>
		</QueryModule>

		<el-button type="primary" @click="onShowDialog">新增文档知识</el-button>

		<el-table v-loading="loading" :data="DocumentKnowledgeTableData" fit stripe>
			<el-table-column label="ID" prop="id" />
			<el-table-column label="标题" prop="title" />
			<el-table-column label="简介" prop="introduction" />
			<el-table-column label="内容" prop="content" />
			<el-table-column label="操作">
				<template #default="scope">
					<el-tooltip class="item" effect="light" content="查看知识" placement="top-end">
						<el-button type="primary" circle size="mini" @click="PreviewDocumentKnowledge(scope.row)">
							<el-icon :size="15"><i-folder-opened /></el-icon>
						</el-button>
					</el-tooltip>
					<el-tooltip class="item" effect="light" content="修改知识" placement="top-end">
						<el-button type="primary" circle size="mini" @click="updataDocumentKnowledge(scope.row)">
							<el-icon :size="15"><i-edit /></el-icon>
						</el-button>
					</el-tooltip>
					<el-tooltip class="item" content="删除知识" effect="light" placement="top-end">
						<el-button type="danger" circle size="mini" @click="deleteDocumentKnowledge(scope.row)">
							<el-icon :size="15"><i-delete /></el-icon>
						</el-button>
					</el-tooltip>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			v-model:currentPage="queryModule.documentKnowledgePage"
			:page-size="pageSize"
			:total="DocumentKnowledgetotal"
			background
			layout="total, prev, pager, next, jumper"
			@current-change="handledocumentKnowledgePage"
		/>
	</el-dialog>

	<!-- 修改文档知识弹窗 -->
	<!-- <el-dialog v-model="updateDocumentKnowledge" :title="updateDocumentKnowledgeTitle">
		<el-form ref="DocumentKnowledgeForm" :model="DocumentKnowledgeForm" label-width="100px">
			<el-form-item label="标题:"><el-input v-model="DocumentKnowledgeForm.title" placeholder="请输入文档知识标题" /></el-form-item>
			<el-form-item label="简介:"><el-input v-model="DocumentKnowledgeForm.introduction" placeholder="请输入文档知识简介" /></el-form-item>
			<div class="button_group">
				<el-button v-if="dataSourceUpdate" type="primary" @click="UpdateDocumentKnowledgeSubmit">修 改</el-button>
				<el-button @click="updateDocumentKnowledge = false">取消</el-button>
			</div>
		</el-form>
	</el-dialog> -->

	<!-- 数据质量弹框 -->
	<el-dialog v-model="qualityKnowledge" :title="dataSourceTitle" fullscreen>
		<QueryModule :query-module="queryModule" @queryRes="qualityknowledgeRes" @reset="qualityknowledgeReset">
			<el-col :span="8">
				<el-form-item label="数据质量标题"><el-input v-model="queryModule.qualityKnowledgeName" /></el-form-item>
			</el-col>
		</QueryModule>

		<el-table v-loading="loading" :data="qualityData" fit stripe>
			<el-table-column label="ID" prop="id" />
			<el-table-column label="标题" prop="title" />
			<el-table-column label="问题来源" prop="issuesSource" />
			<el-table-column label="知识来源" prop="knowledgeSource" />
			<el-table-column label="发生时间" prop="happenTime" />
			<el-table-column label="问题严重级别" prop="issuesLevel" />
			<el-table-column label="问题内容" prop="issuesContent" />
			<el-table-column label="问题负责人" prop="issuesHandleBy" />
			<el-table-column label="问题处理时间" prop="issuesHandleTime" />
			<el-table-column label="操作">
				<template #default="scope">
					<el-tooltip class="item" effect="light" content="查看数据质量" placement="top-end">
						<el-button type="primary" circle size="mini" @click="selectQualityKnowledge(scope.row)">
							<el-icon :size="15"><i-coin /></el-icon>
						</el-button>
					</el-tooltip>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			v-model:currentPage="queryModule.qualityKnowledgePage"
			:page-size="pageSize"
			:total="qualityKnowledgetotal"
			background
			layout="total, prev, pager, next, jumper"
			@current-change="handleQualityKnowledgePage"
		/>
	</el-dialog>

	<el-dialog v-model="dialog.isShow" :title="dialog.title">
		<el-form ref="myProject" :model="myProject" :rules="knowledgeRules">
			<el-form-item label="知识库标题" prop="knowledgeName"><el-input v-model="myProject.knowledgeName" autocomplete="off" placeholder="请输入知识库名称" /></el-form-item>
			<el-form-item label="知识库简介" prop="knowledgeDesc"><el-input v-model="myProject.knowledgeDesc" placeholder="请输入知识库简介" type="textarea" /></el-form-item>
			<el-form-item label="知识库类型" prop="knowledgeType">
				<el-radio-group v-model="myProject.knowledgeType">
					<el-radio :label="1">数据质量</el-radio>
					<el-radio :label="2">文档知识</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="分类标题:" prop="knowledgeTitle">
				<el-select v-model="myProject.knowledgeTitle" style="width: 100%">
					<el-option v-for="item in databaseTotal" :key="item.value" :label="item.label" :value="item.label" />
				</el-select>
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button type="primary" @click="addProject('myProject')">确 定</el-button>
        <el-button @click="dialog.isShow = false">取 消</el-button>
			</span>
		</template>
	</el-dialog>

	<el-dialog v-model="dialog.isQualityShow" :title="dialog.Qualitytitle" fullscreen>
		<el-descriptions title="数据质量信息" :column="4" border>
			<el-descriptions-item label="数据质量标题">{{ qualityDataList.title }}</el-descriptions-item>
			<el-descriptions-item label="内容">{{ qualityDataList.content == null ? '' : qualityDataList.content }}</el-descriptions-item>
			<el-descriptions-item label="具体描述内容">{{ qualityDataList.issuesContent == null ? '' : qualityDataList.issuesContent }}</el-descriptions-item>
		</el-descriptions>

		<el-descriptions title="数据质量规则" :column="4" border class="m-t-20">
			<div v-for="(item, index) in qualityRule" :key="index">
				<el-descriptions-item label="规则名">{{ item.name }}</el-descriptions-item>
				<el-descriptions-item label="规则内容">{{ item.content == null ? '' : item.content }}</el-descriptions-item>
				<el-descriptions-item label="规则类型">{{ item.type == null ? '' : item.type }}</el-descriptions-item>
			</div>
		</el-descriptions>

		<el-descriptions title="数据质量信息源" :column="4" border class="m-t-20">
			<div v-for="(item, index) in qualityRelationObject" :key="index">
				<el-descriptions-item label="数据源主键">{{ item.dsId == null ? '' : item.dsId }}</el-descriptions-item>
				<el-descriptions-item label="数据源中英文名">{{ item.dsName == null ? '' : item.dsName }}</el-descriptions-item>
				<el-descriptions-item label="数据源表中英文名">{{ item.dsTab == null ? '' : item.dsTab }}</el-descriptions-item>
				<el-descriptions-item label="数据源表中列中英文名">{{ item.dsCols == null ? '' : item.dsCols }}</el-descriptions-item>
			</div>
		</el-descriptions>
	</el-dialog>

	<RichTextDialog
		:preview="preview"
		ref="richTextDialogRef"
		v-model:display="display"
		title="新增文档知识"
		:richTextModel="richTextModel"
		:richTextModelRules="richTextModelRules"
		@saveContent="saveDocument"
	>
		<el-col :span="24">
			<el-form-item label="知识标题" prop="title"><el-input v-model="richTextModel.title"></el-input></el-form-item>
		</el-col>
		<el-col :span="24">
			<el-form-item label="知识简介" prop="introduction"><el-input v-model="richTextModel.introduction"></el-input></el-form-item>
		</el-col>
	</RichTextDialog>
</template>
<script>
import BreadCrumb from '/src/components/BreadCrumb';
import QueryModule from '/src/components/QueryModule';
import RichTextDialog from '/src/components/RichTextDialog';
import { mapGetters } from 'vuex';

export default {
	name: 'knowSort',
	components: {
		BreadCrumb,
		QueryModule,
		RichTextDialog
	},
	provide: {
		richTextEditorConfig: { height: 200, dir: 'knowledge' }
	},
	data() {
		const checkCompanyName = (rule, value, callback) => {
			if (value.trim() == '') {
				callback(new Error('请输入规范的数据'));
				return false;
			}
			return true;
		};
		return {
			loading: true,
			knowledgeBaseTree_data: '', //tree信息
			tableData: '', //知识库数据
			DocumentKnowledgeTableData: '', //文档知识数据
			DocumentKnowledgetotal: 0, //文档知识总数
			qualityKnowledgetotal: 0, //数据质量总数
			DocumentKnowledId: 0, //文档知识id
			qualityKnowledgeId: 0, //数据质量知识id
			KnowledgeType: 0, //知识类型
			total: 0,
			id: 0, //知识库id
			dataSourceAdd: false, //新增数据源弹窗
			updateDocumentKnowledge: false, //修改文档知识弹窗
			dataSourceTitle: '', //获取数据源的title
			dataSourceUpdate: false, //修改按钮
			DocumentKnowledge: false, //文档知识弹窗
			updateDocumentKnowledgeTitle: '', //修改文档知识的标题
			documentKnowledgeId: 0,
			categoryId: 0,
			qualityData: '', //数据质量数据
			qualityKnowledge: false, //数据质量弹窗
			databaseTotal: [], //分类标题
			rowData: '', //查询数据
			KnowledgeTotal: '',
			categoryTitle: '',
			qualityDataList: '', //数据质量详细 数据质量信息内容
			qualityRule: '', //数据质量详细 数据质量规则内容
			qualityRelationObject: '', //数据质量详细 数据质量信息源内容
			preview: false,
			form: {
				knowledgeBaseTitle: '',
				knowledgeBaseSummary: ''
			},
			knowledgeRules: {
				//新增用户表单验证
				knowledgeName: [
					{
						required: true,
						message: '知识库标题不可为空',
						trigger: 'blur'
					},
					{ required: true, validator: checkCompanyName, trigger: 'change' }
				],
				knowledgeDesc: [
					{
						required: true,
						message: '知识库简介不可为空',
						trigger: 'blur'
					},
					{ required: true, validator: checkCompanyName, trigger: 'change' }
				],
				knowledgeType: [
					{
						required: true,
						message: '请选择知识库类型',
						trigger: 'blur'
					}
				],
				knowledgeTitle: [
					{
						required: true,
						message: '请选择分类标题',
						trigger: 'change'
					}
				]
			},
			updateKnowledgeRules: {
				knowledgeBaseTitle: [
					{
						required: true,
						message: '知识库标题不可为空',
						trigger: 'blur'
					},
					{ required: true, validator: checkCompanyName, trigger: 'change' }
				],
				knowledgeBaseSummary: [
					{
						required: true,
						message: '知识库简介不可为空',
						trigger: 'blur'
					},
					{ required: true, validator: checkCompanyName, trigger: 'change' }
				]
			},
			dialog: {
				isShow: false,
				title: '新增知识库',
				isQualityShow: false,
				Qualitytitle: ''
			},
			myProject: {
				//新增
				knowledgeName: '',
				knowledgeDesc: '',
				knowledgeType: 1,
				knowledgeTitle: ''
			},
			DocumentKnowledgeForm: {
				title: '',
				introduction: '',
				content: '',
				html_content: ''
			},
			queryModule: {
				timeCond: null,
				createBy: '',
				projectName: '',
				page: 1,
				documentKnowledgePage: 1,
				qualityKnowledgePage: 1,
				documentKnowledgeName: '',
				qualityKnowledgeName: ''
			},
			documentKnowledgeData: {
				documentKnowledgeTitle: '', //新增文档知识标题
				documentKnowledgeIntroduction: '', //新增文档知识简介
				documentKnowledgeContent: '', //新增文档知识内容
				documentKnowledgeHtmlContent: '', //新增文档知识网页内容
				documentKnowledgeId: 0
			},
			display: false,
			richTextModel: {
				title: '',
				introduction: ''
			},
			richTextModelRules: {
				title: [
					{
						required: true,
						message: '请输入知识标题!',
						trigger: 'blur'
					},
					{
						min: 3,
						max: 15,
						message: '请输入标题3-15个字符!',
						trigger: 'blur'
					}
				],
				introduction: [
					{
						required: true,
						message: '请输入知识简介!',
						trigger: 'blur'
					},
					{
						min: 3,
						max: 30,
						message: '请输入知识简介3-30个字符!',
						trigger: 'blur'
					}
				]
			}
		};
	},
	computed: {
		...mapGetters(['pageSize', 'user'])
	},
	mounted: function() {
		const that = this;
		that.queryModule.createBy = that.user.username;
		that.projectList();
		that.dataKnowledgeBaseCategory('0');
	},
	methods: {
		saveDocument(richTextContent) {
			let _this = this;
			//保存修改新增的知识库
			this.documentKnowledgeData.documentKnowledgeTitle = this.richTextModel.title;
			this.documentKnowledgeData.documentKnowledgeIntroduction = this.richTextModel.introduction;
			this.documentKnowledgeData.documentKnowledgeContent = richTextContent.text;
			this.documentKnowledgeData.documentKnowledgeHtmlContent = richTextContent.html;
			this.documentKnowledgeData.documentKnowledgeId = this.DocumentKnowledId;
			let datas = {
				id: this.documentKnowledgeId,
				title: this.documentKnowledgeData.documentKnowledgeTitle,
				introduction: this.documentKnowledgeData.documentKnowledgeIntroduction,
				content: this.documentKnowledgeData.documentKnowledgeContent,
				htmlContent: this.documentKnowledgeData.documentKnowledgeHtmlContent,
				knowledge_base_id: this.documentKnowledgeData.documentKnowledgeId
			};
			this.$axios.PUT('/api/v1/core/knowledgeBase/insertDocumentKnowledge', datas).then(res => {
				if (res.code == 200) {
					this.display = false;
					this.$message({
						type: 'success',
						message: '新增文档知识成功!'
					});
					this.toRrojectDetails(_this.rowData);
					this.richTextModel = { title: '', introduction: '' };
				} else if (res.code == 400) {
					this.display = true;
				} else {
					this.$message({ showClose: true, message: '文档知识新增失败', type: 'error' });
				}
			});
		},
		onShowDialog(abcdefg) {
			console.log(abcdefg);
			this.preview = false;
			this.display = true;
			//this.richTextModel = {"a":"率达了"}
			this.$refs.richTextDialogRef.echo({ text: '', html: '' });
		},
		reset() {
			//知识库重置
			const that = this;
			that.queryModule.projectName = '';
			that.queryModule.page = 1;
			that.projectList();
		},
		queryRes() {
			//知识库查询
			this.projectList();
		},
		knowledgeRes() {
			//文档知识查询
			this.toRrojectDetails(this.rowData);
		},
		knowledgereset() {
			//文档知识重置
			this.queryModule.documentKnowledgeName = '';
			this.queryModule.documentKnowledgePage = 1;
			this.toRrojectDetails(this.rowData);
		},
		qualityknowledgeRes() {
			//数据质量知识查询
			this.toRrojectDetails(this.rowData);
		},
		qualityknowledgeReset() {
			//数据质量知识重置
			this.queryModule.qualityKnowledgeName = '';
			this.queryModule.qualityKnowledgePage = 1;
			this.toRrojectDetails(this.rowData);
		},
		//树状图展示知识
		dataKnowledgeBaseCategory(data) {
			// const that = this;
			const a = data;
			this.$axios.GET('/api/v1/core/knowledgeBase/findKnowledgeBaseCategory/' + a).then(res => {
				if (res.code == 200) {
					this.knowledgeBaseTree_data = JSON.parse(JSON.stringify(res.data));
				} else {
					this.$message({ showClose: true, message: '查询失败', type: 'error' });
				}
			});
		},
		//点击树状图方法
		treeClick() {},
		//树状图添加知识
		appendKnowledgeBase(data) {
			if (data == 0) {
				//添加根知识
				this.$prompt('请输入添加的知识名称', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					// inputPattern: /^[\u4e00-\u9fa5]{0,}$/,
					inputValidator(value) {
						if (!value) {
							return '知识分类名称不能为空';
						} else if (! /^[\u4e00-\u9fa5]{0,}$/.test(value)) {
							return '请输入中文知识分类名称';
						} 
							return true
					},
					inputErrorMessage: '请输入中文知识名称'
				})
					.then(({ value }) => {
						const rootNodeData = {
							categoryTitle: value,
							sorted: 0,
							parentId: 0
						};
						this.$axios.PUT('/api/v1/core/knowledgeBase/createKnowledgeBaseCategory', rootNodeData).then(res => {
							if (res.code == 200) {
								const newRootNode = {
									id: res.data,
									label: value,
									children: []
								};
								this.knowledgeBaseTree_data.push(newRootNode);
								this.$message({
									type: 'success',
									message: '新增知识: ' + value
								});
							} else if (res.code == 400) {
								this.$message({
									type: 'error',
									message: res.message
								});
							} else {
								this.$message({
									type: 'info',
									message: '添加知识失败'
								});
							}
						});
					})
					.catch(() => {
						this.$message({
							type: 'info',
							message: '取消添加知识'
						});
					});
			} else {
				//添加子节点
				this.$prompt('请输入添加的子知识分类名称', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					// inputPattern: /^[\u4e00-\u9fa5]{0,}$/,
					inputValidator(value) {
						if (!value) {
							return '知识分类名称不能为空';
						} else if (! /^[\u4e00-\u9fa5]{0,}$/.test(value)) {
							return '请输入中文知识分类名称';
						} 
							return true
					},
					inputErrorMessage: '请输入中文知识分类名称'
				})
					.then(({ value }) => {
						if (!data.children) {
							data.children = [];
						}
						const childNodeData = {
							categoryTitle: value,
							sorted: 0,
							parentId: data.id
						};
						this.$axios.PUT('/api/v1/core/knowledgeBase/createKnowledgeBaseCategory', childNodeData).then(res => {
							if (res.code == 200) {
								const newChild = {
									id: res.data,
									label: value,
									children: []
								};
								data.children.push(newChild);
								this.$message({
									type: 'success',
									message: '新增子知识分类: ' + value + '成功!'
								});
							} else if (res.code == 400) {
								this.$message({
									type: 'error',
									message: res.message
								});
							} else {
								this.$message({
									type: 'info',
									message: '添加知识分类失败'
								});
							}
						});
					})
					.catch(() => {
						this.$message({
							type: 'info',
							message: '取消添加知识分类'
						});
					});
			}
		},
		//tree重命名节点==================================================================================================================
		rename(data) {
			this.$prompt('请输入新的知识分类名称', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				inputPattern: /^[\u4e00-\u9fa5]{0,}$/,
				inputErrorMessage: '请输入中文知识分类名称',
				inputValue: data.label
			}).then(({ value }) => {
				const rootNodeData = {
					id: data.id,
					categoryTitle: value
				};
				this.$axios.PUT('/api/v1/core/knowledgeBase/updateKnowledgeBaseCategory', rootNodeData).then(res => {
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: '修改' + data.label + '知识分类为 ' + value + '成功！'
						});
						data.label = value;
					} else {
						this.$message({
							type: 'info',
							message: '修改' + data.label + '知识分类失败！'
						});
					}
				});
			});
		},

		//tree删除子节点==================================================================================================================
		remove(node, data) {
			this.$confirm('此操作将永久删除该节点, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$axios.DELETE('/api/v1/core/knowledgeBase/deleteKnowledgeBaseCategory/' + data.id).then(res => {
						if (res.code == 200) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
							const parent = node.parent;
							const children = parent.data.children || parent.data;
							const index = children.findIndex(d => d.id === data.id);
							children.splice(index, 1);
						} else if (res.code == 400) {
							this.$message({
								type: 'error',
								message: res.message
							});
						} else {
							this.$message({
								type: 'info',
								message: '删除失败！'
							});
						}
					});
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
		},
		//树状图方法
		renderContent(
			h,
			{
				node,
				data
				// store
			}
		) {
			return h(
				'span',
				{
					class: 'custom-tree-node'
				},
				h('span', { onClick: () => this.projectList(data.id) }, null, node.label),
				h(
					'span',
					{ class: 'd-flex-justify-center' },
					null,
					h(
						'a',
						{
							class: 'tree_rename_button',
							onClick: () => this.rename(data),
							style: {
								margin: '5px',
								color: '#409EFF'
							}
						},
						''
					),
					h(
						'a',
						{
							class: 'tree_append_button',
							onClick: () => this.appendKnowledgeBase(data),
							style: {
								margin: '5px',
								color: '#409EFF'
							}
						},
						''
					),
					h(
						'a',
						{
							class: 'tree_remove_button',
							onClick: () => this.remove(node, data),
							style: {
								margin: '10px',
								color: '#909399'
							}
						},
						''
					)
				)
			);
		},
		// //知识库数据展示
		projectList(data) {
			const that = this;
			let a = data;
			this.$axios
				.POST('/api/v1/core/knowledgeBase/allKnowledgeBasePage', {
					page: that.queryModule.page,
					size: that.pageSize,
					knowledgeBaseTitle: that.queryModule.projectName,
					categoryId: a
				})
				.then(res => {
					if (res.code == 200) {
						that.loading = false;
						that.tableData = res.data.list;
						that.KnowledgeTotal = that.tableData.knowledgeBaseTitle;
						for (let item of that.tableData) {
							if (item.knowledgeBaseType == 1) {
								item.knowledgeBaseType = '数据质量';
							} else {
								item.knowledgeBaseType = '文档知识';
							}
						}
						that.total = res.data.total;
					} else {
						this.$message({ showClose: true, message: '查询失败', type: 'error' });
					}
				});
		},
		//删除数据源按钮==================================================================================================================
		delentSourceData(row) {
			this.$confirm('确定删除id为' + row.id + '的知识库', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$axios.DELETE('/api/v1/core/knowledgeBase/upDateIsDeleted/' + row.id).then(res => {
						if (res.code == 200) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
							this.projectList();
						} else {
							this.$message({ showClose: true, message: '删除失败', type: 'error' });
						}
					});
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
		},
		//修改前获取知识库的信息
		updataSourceData(row) {
			this.$axios.GET('/api/v1/core/knowledgeBase/datasource/' + row.id).then(res => {
				if (res.code == 200) {
					this.id = res.data.id;
					this.form.knowledgeBaseTitle = res.data.knowledgeBaseTitle;
					this.form.knowledgeBaseSummary = res.data.knowledgeBaseSummary;
				} else {
					this.$message({ showClose: true, message: '消息获取失败', type: 'error' });
				}
			});
			this.dataSourceTitle = '修改知识库';
			this.dataSourceAdd = true;
			this.dataSourceUpdate = true;
		},

		//修改知识库信息==================================================================================================================
		UpdateOnSubmit(from) {
			let datas = {
				id: this.id,
				knowledgeBaseTitle: this.form.knowledgeBaseTitle,
				knowledgeBaseSummary: this.form.knowledgeBaseSummary
			};
			this.$refs[from].validate(valid => {
				if (valid) {
					this.$axios.PUT('/api/v1/core/knowledgeBase/updateKnowledgeBase', datas).then(res => {
						if (res.code == 200) {
							this.dataSourceAdd = false;
							this.$message({
								type: 'success',
								message: '修改成功!'
							});
							this.projectList();
						} else if (res.code == 400) {
							this.$message({
								type: 'error',
								message: res.message
							});
						} else {
							this.$message({ showClose: true, message: '修改失败', type: 'error' });
						}
					});
				}
			});
		},
		//新增知识库弹框
		addDialog() {
			this.$axios.GET('/api/v1/core/knowledgeBase/findCategoryTitle').then(res => {
				if (res.code == 200) {
					this.databaseTotal = [];
					$.each(res.data, (key, value) => {
						const dropDownFrame = { value: '', label: '' };
						dropDownFrame.value = key;
						dropDownFrame.label = value.category_title;
						this.databaseTotal.push(dropDownFrame);
					});
				} else {
					this.$message({ showClose: true, message: '修改失败', type: 'error' });
				}
			});
			this.myProject.knowledgeId = 1;
			this.dialog.isShow = true;
			Object.keys(this.myProject).forEach(key => (this.myProject[key] = ''));
		},
		//新增知识库按钮
		addProject(myProject) {
			var that = this;
			let { knowledgeName, knowledgeDesc, knowledgeType, knowledgeTitle } = that.myProject;
			that.$refs[myProject].validate(valid => {
				if (valid) {
					let datas = {
						knowledgeBaseTitle: knowledgeName,
						knowledgeBaseSummary: knowledgeDesc,
						knowledgeBaseType: knowledgeType,
						categoryTitle: knowledgeTitle
					};
					this.$axios.PUT('/api/v1/core/knowledgeBase/insertKnowledgeBase', datas).then(function(result) {
						that.dialog.isShow = false;
						if (result.code == 200) {
							that.$message({
								showClose: true,
								message: '知识库新增成功',
								type: 'success'
							});
							that.projectList();
						} else if (result.code == 400) {
							that.dialog.isShow = true;
						} else {
							this.$message({ showClose: true, message: '知识库新增失败', type: 'error' });
						}
					});
				} else {
					return false;
				}
			});
		},

		//预览知识
		toRrojectDetails(row, isDel) {
			if (isDel == undefined) {
				this.DocumentKnowledId = row.id;
				this.dataSourceTitle = row.knowledgeBaseTitle;
				this.KnowledgeType = row.knowledgeBaseType;
				this.qualityKnowledgeId = row.id;
				this.rowData = row;
			}
			if (this.KnowledgeType == '数据质量') {
				this.$axios
					.POST('/api/v1/core/knowledgeBase/findAllQualityKnowledge', {
						page: this.queryModule.qualityKnowledgePage,
						size: this.pageSize,
						knowledge_base_id: this.qualityKnowledgeId,
						title: this.queryModule.qualityKnowledgeName
					})
					.then(res => {
						if (res.code == 200) {
							this.qualityData = res.data.list;
							this.qualityKnowledgetotal = res.data.total;
							for (let item of this.qualityData) {
								if (item.issuesSource == 1) {
									item.issuesSource = '告警';
								} else if (item.issuesSource == 2) {
									item.issuesSource = '手工';
								} else if (item.issuesSource == 3) {
									item.issuesSource = '评估';
								}
							}
							for (let item of this.qualityData) {
								if (item.knowledgeSource == 1) {
									item.knowledgeSource = '问题总结';
								} else if (item.knowledgeSource == 2) {
									item.knowledgeSource = '质量报告';
								} else if (item.knowledgeSource == 3) {
									item.knowledgeSource = '手工创建';
								}
							}
						} else {
							this.$message({ showClose: true, message: '修改失败', type: 'error' });
						}
					});
				this.qualityKnowledge = true;
			} else if (this.KnowledgeType == '文档知识') {
				this.$axios
					.POST('/api/v1/core/knowledgeBase/findAllDocumentKnowledge', {
						page: this.queryModule.documentKnowledgePage,
						size: this.pageSize,
						id: this.DocumentKnowledId,
						title: this.queryModule.documentKnowledgeName
					})
					.then(res => {
						if (res.code == 200) {
							this.DocumentKnowledgeTableData = res.data.list;
							this.DocumentKnowledgetotal = res.data.total;
						} else {
							this.$message({ showClose: true, message: '修改失败', type: 'error' });
						}
					});
				this.DocumentKnowledge = true;
			}
		},
		//根据id删除文档知识
		deleteDocumentKnowledge(row) {
			var _this = this;
			this.$confirm('确定删除id为' + row.id + '的文档知识', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$axios.DELETE('/api/v1/core/knowledgeBase/documentKnowledgeDeleted/' + row.id).then(res => {
						if (res.code == 200) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
							_this.toRrojectDetails(this.DocumentKnowledId, true);
						} else {
							this.$message({ showClose: true, message: '修改失败', type: 'error' });
						}
					});
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
		},
		//修改文档知识前获取文档知识数据。
		updataDocumentKnowledge(row) {
			(this.preview = false),
				this.$axios.GET('/api/v1/core/knowledgeBase/getDocumentKnowledgeById/' + row.id).then(res => {
					if (res.code == 200) {
						this.$refs.richTextDialogRef.reset();
						this.documentKnowledgeId = res.data.id;
						this.DocumentKnowledgeForm.title = res.data.title;
						this.DocumentKnowledgeForm.introduction = res.data.introduction;
						this.DocumentKnowledgeForm.content = res.data.content;
						this.DocumentKnowledgeForm.html_content = res.data.htmlContent;
						this.display = true;
						this.richTextModel = { title: this.DocumentKnowledgeForm.title, introduction: this.DocumentKnowledgeForm.introduction };
						this.$refs.richTextDialogRef.echo({ text: this.DocumentKnowledgeForm.content, html: res.data.htmlContent });
					} else {
						this.$message({ showClose: true, message: '修改失败', type: 'error' });
					}
				});
		},
		//预览文档知识库
		PreviewDocumentKnowledge(row) {
			this.preview = true;
			this.$axios.GET('/api/v1/core/knowledgeBase/getDocumentKnowledgeById/' + row.id).then(res => {
				if (res.code == 200) {
					this.$refs.richTextDialogRef.reset();
					this.documentKnowledgeId = res.data.id;
					this.DocumentKnowledgeForm.title = res.data.title;
					this.DocumentKnowledgeForm.introduction = res.data.introduction;
					this.DocumentKnowledgeForm.content = res.data.content;
					this.DocumentKnowledgeForm.html_content = res.data.htmlContent;
					this.display = true;
					this.richTextModel = { title: this.DocumentKnowledgeForm.title, introduction: this.DocumentKnowledgeForm.introduction };
					this.$refs.richTextDialogRef.echo({ text: this.DocumentKnowledgeForm.content, html: res.data.htmlContent });
				} else {
					this.$message({ showClose: true, message: '修改失败', type: 'error' });
				}
			});
		},
		selectQualityKnowledge(row) {
			this.$axios.GET('/api/v1/core/knowledgeBase/getQualityKnowledgeById/' + row.id).then(res => {
				if (res.code == 200) {
					this.qualityDataList = res.data;
					this.qualityRule = res.data.qualityKnowledgeRules;
					this.qualityRelationObject = res.data.qualityKnowledgeRelationObjects;
					this.dialog.isQualityShow = true;
					this.dialog.Qualitytitle = res.data.title;
				} else {
					this.$message({ showClose: true, message: '修改失败', type: 'error' });
				}
			});
		},
		//修改文档知识
		// UpdateDocumentKnowledgeSubmit() {
		// 	let datas = {
		// 		id: this.documentKnowledgeId,
		// 		title: this.DocumentKnowledgeForm.title,
		// 		introduction: this.DocumentKnowledgeForm.introduction
		// 	};
		// 	this.$axios.PUT('/api/v1/core/knowledgeBase/updateDocumentKnowledge', datas).then(res => {
		// 		if (res.code == 200) {
		// 			this.updateDocumentKnowledge = false;
		// 			this.$message({
		// 				type: 'success',
		// 				message: '修改成功!'
		// 			});
		// 			this.toRrojectDetails(this.DocumentKnowledId, true);
		// 		} else if (res.code == 400) {
		// 			this.$message({
		// 				type: 'info',
		// 				message: res.message
		// 			});
		// 		}
		// 	});
		// },

		handleCurrentChange(currentPage) {
			const that = this;
			console.log(currentPage);
			that.queryModule.page = currentPage;
			console.log(that.queryModule.page);
			that.projectList();
		},
		handledocumentKnowledgePage(currentPage) {
			const that = this;
			that.queryModule.documentKnowledgePage = currentPage;
			that.toRrojectDetails(that.rowData);
		},
		handleQualityKnowledgePage(currentPage) {
			const that = this;
			that.queryModule.qualityKnowledgePage = currentPage;
			that.toRrojectDetails(that.rowData);
		}
	}
};
</script>
<style>
.button_group {
	width: 100%;
	display: flex;
	justify-content: center;
}
</style>
